








import { Component, Vue } from 'vue-property-decorator'
const Header = () => import('@/views/header.vue')
const Footer = () => import('@/views/footer.vue')

@Component({
  components: { Footer, Header }
})
export default class Main extends Vue {

}
